const orderCreateState = {
  addDiscountModal: {
    activeTab: 'percentage',
    errors: [],
    isOpen: false,
    isSubmitting: false,
    monetaryDiscount: '',
    percentageDiscount: '',
  },
  // hasPriceEditPermission: false,
  isLoadingGrid: false,
  isLoadingGridItems: false,
  isLoadingFilters: false,
  isLoadingOrder: false,
  isLoadingProductVariants: false,
  grid: {
    filters: {
      brands: [],
      categories: [],
    },
    gridErrors: [],
    itemErrors: [],
    items: [],
    orderErrors: [],
    pageParams: {
      page: 1,
      perPage: 25,
      resultCount: 0,
      sortBy: {
        id: 'product_name',
        desc: false,
      },
      sortByOptions: [],
    },
    sortableColumns: [],
  },
  order: {
    errors: [],
    hasMoreItems: false,
    id: null,
    isAddingAllItems: false,
    isDeletingAllItems: false,
    isLoadingDrawerItems: false,
    items: [],
    itemsBeingAdded: [],
    itemsBeingRemoved: [],
    itemsPageParams: {
      page: 1,
      perPage: 25,
      resultCount: 0,
    },
    totals: {
      filteredQuantity: 0,
      filteredPrice: 0,
      orderMinimum: 0,
      totalQuantity: 0,
      totalPrice: 0,
      currency: 'USD',
    },
  },
  productVariants: {
    filters: {},
    itemErrors: [],
    pageParams: {
      page: 1,
      perPage: 25,
      resultCount: 0,
      sortBy: {
        id: 'name',
        desc: false,
      },
    },
    searchValue: '',
    selectedBrands: [],
    selectedCategories: [],
    variants: [],
  },
  orderCreateModal: {
    customers: [],
    isLoadingCustomers: false,
    isLoadingLocations: false,
    isLoadingStockLocations: false,
    isOpen: true,
    locations: [],
    selectedCustomer: null,
    selectedLocation: null,
    selectedStockLocation: null,
    stockLocations: [],
  },
  tablePreferences: {},
  vendorId: null,
};

export default orderCreateState;
